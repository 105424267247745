import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Order } from 'src/app/shared/models/order/order.class';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService, SortEvent } from 'primeng/api';
import { DetailsOrderModalComponent } from '../../modals/details-order-modal/details-order-modal.component';
import { DatePromiseModalComponent } from '../../modals/date-promise-modal/date-promise-modal.component';
import { AssignOrderModalNoteComponent } from '../../modals/assign-order-modal-note/assign-order-modal-note.component';
import { DatePipe } from '@angular/common';
import { OrderService } from 'src/app/api/services/order/order.service';

@Component({
  selector: 'app-table-order',
  templateUrl: './table-order.component.html',
  styleUrls: ['./table-order.component.css'],
})
export class TableOrderComponent implements OnInit {
  @Input()
  orders: Order[] = [];

  @Input()
  paginate = 1;

  @Input()
  totalRecords = 0;

  @Input()
  size = 10;

  @Output()
  eventEmitterTable: EventEmitter<any> = new EventEmitter();

  orderSelected: Order = null;

  ref: DynamicDialogRef | undefined;

  visibleAssignOrder: boolean = false;

  constructor(private dialogService: DialogService, private datepipe: DatePipe, private orderService: OrderService, private messageService: MessageService) { }
  ngOnInit(): void {
    for (let i = 0; i < this.orders.length; i++) {
        let fechaActual = new Date(this.datepipe.transform(new Date(),'M/d/yy'))
        let fechaPedido = new Date(this.datepipe.transform(this.orders[i].fechaDePedido,'M/d/yy'))
        const diferencia = Math.abs(fechaActual.getTime() - fechaPedido.getTime());
        const dias = Math.ceil(diferencia / (1000 * 3600 * 24));
        this.orders[i].lt = dias;
    }

    console.log(this.orders);
    
  }

  showModal(order: Order) {
    this.ref = this.dialogService.open(AssignOrderModalNoteComponent, {
      header: '',
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      styleClass: 'modal-assign-order',
      data: {
        order: order,
      },
      maskStyleClass: ""
    });

    this.ref.onClose.subscribe((data: any) => {
      if (data && data.success) {
        this.eventEmitterTable.emit(true)
      }
    });
  }

  showModalOrderWarning(order: Order) {
    this.ref = this.dialogService.open(AssignOrderModalNoteComponent, {
      header: '',
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      styleClass: 'modal-assign-order',
      data: {
        order: order,
      },
      maskStyleClass: ""
    });
    this.ref.onClose.subscribe((data: any) => {
      if (data && data.success) {
        this.eventEmitterTable.emit(true)
      }
    });
  }

  showModalDetailOrder(order: Order) {
    this.ref = this.dialogService.open(DetailsOrderModalComponent, {
      header: '',
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      styleClass: '',
      data: {
        order: order,
      },
    });

    this.ref.onClose.subscribe((data: any) => {
      if (data && data.success) {
        this.eventEmitterTable.emit(true)
      }
    });
  }

  showModalDatePromiseOrder(order: Order) {
    this.ref = this.dialogService.open(DatePromiseModalComponent, {
      header: '',
      width: '50%',
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: false,
      styleClass: 'modal-details-order',
      data: {
        order: order,
      },
    });

    this.ref.onClose.subscribe((data: any) => {
      if (data && data.success) {
        this.eventEmitterTable.emit(true)
      }
    });
  }

  calculateIsStock(order: any) {
    if (order.hayStock) {
      let cantidad = order.cantidad
      let available = false;
      for (let i = 0; i < order.stock.length; i++) {
        if (order.stock[i].cantidad >= cantidad) {
          available = true;
          break;
        } else {
          continue;
        }
      }
      return available
    }
    return false;
  }

  changeDatePromise(event: any, order: any) {
    let fechaPrometida = event
      ? this.datepipe.transform(
        new Date(event),
        'd/MM/yyyy'
      )
      : '';

    this.orderService
      .updateDatePromiseOrder(order, { fechaPrometida })
      .subscribe({
        next: (data) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Guardado',
          });
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: err.error ? err.error.message : 'Ups! Ocurrio un error',
          });
        },
        complete: () => {
          this.eventEmitterTable.emit(true)
        },
      });
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        if (event.field === 'stock') {
          // event.order = event.order == 1 ? 1 : -1
          result = value1 < value2 ? 1 : value1 > value2 ? -1 : 0;

          if (result == 1 && !value1 && !value2) {
            result = -1
          } else if (result == -1 && !value1 && !value2) {
            result = 1;
          }

        } else {
          result = value1 < value2 ? 1 : value1 > value2 ? -1 : 0;
        }
      }

      console.log(this.orders);
      

      return event.order * result;
    });
  }

  loadOrderLazy(event: any) {
    //simulate remote connection with a timeout
    setTimeout(() => {
      //load data of required page
      let loadedOrders = this.orders.slice(
        event.first,
        event.first + event.rows
      );

      //populate page of virtual cars
      Array.prototype.splice.apply(this.orders, [
        ...[event.first, event.rows],
        ...loadedOrders,
      ]);

      //trigger change detection
      event.forceUpdate();
    }, Math.random() * this.totalRecords + this.size);
  }
}
